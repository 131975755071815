<template>
    <div class="menual">
        <div class="topTitle">{{shortTitle}}</div>
        <hr  class="hr">
        <div class="content_container">
            <div class="content"  v-loading="loading">
                <div class="thead">
                    <div class="td1">资料名称</div>
                    <div class="td2">版本</div>
                    <div class="td3">上传时间</div>
                    <div class="td4">资料大小</div>
                </div>
                <div class="tr" v-for="(item,index) in list" :key="index" >
                    <div class="tr1">
                        <div class="td1" @click="clickResourceName(item)">{{item.name}}</div>
                        <div class="td2">{{item.version}}</div>
                        <div class="td3">{{item.uploadTime}}</div>
                        <div class="td4">{{item.size}}</div>
                    </div>
                    <div class="tr2">
                        {{item.content}}
                    </div>
                </div>
                <el-pagination
                    class="pagination"
                    @current-change="currentChange"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    layout="prev, pager, next, total"
                    :current-page.sync="currentPage"
                    :pager-count="5"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        
    </div>
</template>
<script>
import { productManual, developResource, toolSoft, other,} from '@/services/serviceSupport.js'
export default {
  name: "downloadManual",
  components:{
  },
  props:[
  ],
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        shortTitle:'',
        list:[],
        total:0,
        currentPage:1,
        n:'',
        loading:false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
  },
  activated(){
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    init(n){
        this.n = n
        if(n == 'ProductManual'){//产品手册
            this.shortTitle = '产品手册'
            this.getProductManual()
        }else if(n =='DevelopResource'){//开发资源
            this.shortTitle = '开发资源'
            this.getDevelopResource()
        }else if(n =='ToolSoft'){//工具软件
            this.shortTitle = '工具软件'
            this.getToolSoft()
        }else if(n == 'OtherResource'){//其他
            this.shortTitle = '其他'
            this.getOtherResource()
        }
        document.title = this.shortTitle+' - 南京沁恒微电子股份有限公司'
    },
    currentChange(current){
        this.currentPage = current
        if(this.n == 'ProductManual'){//产品手册
            this.getProductManual()
        }else if(this.n =='DevelopResource'){//开发资源
            this.getDevelopResource()
        }else if(this.n =='ToolSoft'){//工具软件
            this.getToolSoft()
        }else if(this.n == 'OtherResource'){//其他
            this.getOtherResource()
        }
    },
    prevClick(prev){
    },
    nextClick(next){
    },
    getProductManual(){
        this.list = []
        this.total = 0
        this.loading = true;
        let params = {
            current:this.currentPage?this.currentPage:1,
        }
        productManual(params).then(res => {
            if(res.status===200){
                this.list = res.data.data
                this.total = res.data.total
                this.currentPage = res.data.current
                console.log('list---',this.list)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    clickResourceName(t){
        let pName = t.name.split('.').join('_')
        this.$router.push({ name: 'ResourceDetail', params: {name:pName+'.html'}})
    },
    getDevelopResource(){
        this.list = []
        this.total = 0
        this.loading = true;
        let params = {
            current:this.currentPage?this.currentPage:1,
        }
        developResource(params).then(res => {
            if(res.status===200){
                this.list = res.data.data
                this.total = res.data.total
                this.currentPage = res.data.current
                console.log('list---',this.list)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    getToolSoft(){
        this.list = []
        this.total = 0
        this.loading = true;
        let params = {
            current:this.currentPage?this.currentPage:1,
        }
        toolSoft(params).then(res => {
            if(res.status===200){
                this.list = res.data.data
                this.total = res.data.total
                this.currentPage = res.data.current
                console.log('list---',this.list)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    getOtherResource(){
        this.list = []
        this.total = 0
        this.loading = true;
        let params = {
            current:this.currentPage?this.currentPage:1,
        }
        other(params).then(res => {
            if(res.status===200){
                this.list = res.data.data
                this.total = res.data.total
                this.currentPage = res.data.current
                console.log('list---',this.list)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
   
  }
};
</script>
<style scoped lang="less">
 .menual {
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    text-align: left;
    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content_container{
        width: 100%;
    }
    .content{
        font-size: 15px !important;
        color: #333;
        .thead{
            display: flex;
            border-bottom: 2px solid #ddd;
            font-weight: 600;
        }
        .tr{
            border-bottom: 1px solid #ddd;
            .tr1{
                display: flex;
                &:hover{
                    background-color: #f5f5f5;
                }
                .td1{
                    color: #337ab7;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .tr2{
                padding: 8px;
                word-break: break-all;
                &:hover{
                    background-color: #f5f5f5;
                }
            }
        }
        .td1{
            flex: 1;
            padding: 8px;
            word-break: break-all;
        }
        .td2{
            width:80px;
            padding: 8px;
        }
        .td3{
            width:100px;
            padding: 8px;
        }
        .td4{
            width:85px;
            padding: 8px;
        }
    }
 }
 .content /deep/.el-pagination{
    white-space: break-spaces !important;
 }
 @media screen and (max-width: 991px) {
    .menual .content_container{
        overflow-x: auto;
        overflow-y: hidden;
        // border: 2px solid #e1e1e1;
        // padding: 10px;
        // box-sizing: border-box;
    }
    .menual .content{
        min-width: 700px;
    }

 }
@media screen and (max-width: 768px) {
    .menual .content .td1 {
        padding: 4px
    }
    .menual .content .td2 {
        padding: 4px;
    }
    .menual .content .td3{
        padding: 4px;
        width: 80px;
    }
    .menual .content .td4{
        padding: 4px;
    }
}

</style>