<template>
    <div class="container_">
        <download-manual ref="downloadManual"></download-manual>
    </div>
</template>
<script>
import downloadManual from '@/pages/components/downloadManual.vue'

export default {
  name: "productManual",
  components:{
    downloadManual,
  },
  props:[
  ],
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        content:{},
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
  },
  activated(){
    this.init(this.$route.name)
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
   
    init(n){
      this.$refs.downloadManual.init(n)
    },
    
    
  }
};
</script>
<style scoped lang="less">

 .container_ {
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    text-align: left;
 }


</style>